$(document).ready(function() {
  ("use strict");

  // --------
  // megamenu
  // --------

  // initialize the megamenu
  $("nav:first").accessibleMegaMenu();

  // clicar el botó
  $(".menu-button").on("click", function() {
    $(this).toggleClass("is-active");
    if ($(this).attr("aria-expanded") == "true") {
      $(this).attr("aria-expanded", "false");
    } else {
      $(this).attr("aria-expanded", "true");
    }
  });

  $(".nav-menu a").on("click", function() {
    if ($(window).width() >= 1024) {
      $(".desplegable button").removeClass("open");
      $(".desplegable ul").attr("aria-hidden", "true");
    }
  });

  // si és touch prevenim click als dropdowns
  if (feature.touch) {
    $("ul.sub-level-group.level-3").hide();
    /*$(".has-sub-nav > a").on("click", function(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    });*/
  }

  if ($(window).width() < 1024) {
    $(".main-nav").attr("aria-hidden", "true");
  }
  $(".menu-button").on("click", function() {
    var atribut = $(".menu-mobil").attr("aria-hidden");

    if (atribut == "true") {
      $(".menu-mobil")
        .attr("aria-hidden", "false")
        .addClass("open");
    } else {
      $(".menu-mobil")
        .attr("aria-hidden", "true")
        .removeClass("open");
    }
  });
  $(window).resize(function() {
    if ($(window).width() > 1023) {
      $(".menu-mobil")
        .attr("aria-hidden", "true")
        .removeClass("open");
      $(".menu-button")
        .attr("aria-expanded", "false")
        .removeClass("is-active");
    }
  });

  $(".main-header .menu").addClass("load");


  // -------------
  // Treure quadre
  // -------------

  $('a').mousedown(function(e) {
    $(this).attr("data-focus-method","mouse");
  });
  $('a').on({ 'touchstart' : function(){ $(this).attr("data-focus-method","touch"); } });

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // ------------
  // Match height
  // ------------

  // $(".h-news--item .h-news-text").matchHeight();
  $(".h-links a").matchHeight();
  $(".h-links2 a").matchHeight();
  $(".publ").matchHeight();
  $(".publ-fitxa").matchHeight();
  // $(".h-agenda__item").matchHeight();
  $(".premsa-list div").matchHeight();
  $(".telf-list div").matchHeight();

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // ----------
  // scroll top
  // ----------

  $(window).scroll(function() {
    if ($(this).scrollTop() > 400) {
      $(".top").fadeIn();
    } else {
      $(".top").fadeOut();
    }
  });
  $('a[name]').addClass('anchor_offset');


  // ------
  // Slider
  // ------

  $(".slider").slick({
    // arrows: false,
    // autoplay: true,
    // autoplaySpeed: 10000,
    dots: true,
    // appendDots: $(".slider__dots")
  });

  //Botó traductor
  $('.lang-container .close').on('click', function( e ) {
    e.preventDefault();
    $('.traductor').attr('aria-expanded', 'false');
    $('.traductor--info').attr('aria-hidden', 'true');
    return false;
  });

  // -----------
  // A11y toggle
  // -----------

  function collapse(toggle) {
    var id = toggle.getAttribute("data-a11y-toggle");
    var collapsibleBox = document.getElementById(id);
    collapsibleBox.setAttribute("aria-hidden", true);
    toggle.setAttribute("aria-expanded", false);
  }

  function collapseAll(event) {
    toggles
      .filter(function(toggle) {
        return toggle !== event.target;
      })
      .forEach(collapse);
  }

  var toggles = Array.prototype.slice.call(
    document.querySelectorAll(".connected-toggles [data-a11y-toggle]")
  );

  toggles.forEach(function(toggle) {
    toggle.addEventListener("click", collapseAll);
  });

  // -----------
  // Collapsible
  // -----------
  $(".collapsible__title a").on("click", function(e) {
    $(".collapsible__wrapper").removeClass("open");
    $(".collapsible__close-icon").remove();
    $(this)
      .closest(".collapsible__wrapper")
      .addClass("open")
      .append(
        "<button type='button' class='collapsible__close-icon'><span class='vh'>Tancar</span><span class='icon'></span></button>"
      );
    return false;
  });
  $("body").on("click", ".collapsible__close-icon", function() {
    $(".collapsible__wrapper").removeClass("open");
    $(".collapsible__close-icon").remove();
    return false;
  });

  // -------------
  // Collapsible 2 (https://heydon.github.io/inclusive-components-demos/collapsible-sections/progressive.html)
  // -------------
  (function() {
    // Get all the <h2> headings
    var headings = document.querySelectorAll(".collapsible-2 .collapsible__title, .collapsible-3 h2");
    Array.prototype.forEach.call(headings, function(heading) {
      // Give each <h2> a toggle button child
      // with the SVG plus/minus icon
      heading.innerHTML = '\n          <button aria-expanded="false">\n            '.concat(
        heading.textContent,
        '\n            <svg focusable="false" viewBox="0 0 24 16">\n              <path d="M24 0H0l12 16z"/>\n            </svg>\n          </button>\n        '
      ); // Function to create a node list
      // of the content between this <h2> and the next

      var getContent = function getContent(elem) {
        var elems = [];

        while (
          elem.nextElementSibling &&
          //elem.nextElementSibling.tagName !== "H2"
          !elem.nextElementSibling.classList.contains('collapsible__title')
        ) {
          elems.push(elem.nextElementSibling);
          elem = elem.nextElementSibling;
        } // Delete the old versions of the content nodes

        elems.forEach(function(node) {
          node.parentNode.removeChild(node);
        });
        return elems;
      }; // Assign the contents to be expanded/collapsed (array)

      var contents = getContent(heading); // Create a wrapper element for `contents` and hide it

      var wrapper = document.createElement("div");
      wrapper.hidden = true; // Add each element of `contents` to `wrapper`

      contents.forEach(function(node) {
        wrapper.appendChild(node);
      }); // Add the wrapped content back into the DOM
      // after the heading

      heading.parentNode.insertBefore(wrapper, heading.nextElementSibling); // Assign the button

      var btn = heading.querySelector("button");

      btn.onclick = function() {
        // Cast the state as a boolean
        var expanded = btn.getAttribute("aria-expanded") === "true"; // Switch the state

        btn.setAttribute("aria-expanded", !expanded); // Switch the content's visibility

        wrapper.hidden = expanded;
      };
    });
  })();
  if($('#redsys_form').length){
      $('#redsys_form').submit();
  }
});




// ----
// Tabs (https://bbc.github.io/gel/components/tabs/)
// ----
/*if ($(".gel-tabs").length > 0) {

document.addEventListener("DOMContentLoaded", function(event) {
  window.gel && gel.Tabs.init();

  var tabsElem = document.querySelector(".gel-tabs");
  var tabs = new gel.Tabs.constructor(tabsElem);
});

(function() {
  if (!window.gel) {
    window.gel = {};
  }
  var self = (gel.Tabs = {});

  self.init = function() {};

  self.constructor = function(elem) {
    var tablist = elem.querySelector(".tabs-list");
    var tabs = elem.querySelectorAll(".tabs-list a");
    var panels = elem.querySelectorAll("section[id]");
    tablist.setAttribute("role", "tablist");

    var tabInfo = function() {
      var focus;
      var tab;
      if (window.location.hash) {
        tab = Array.prototype.indexOf.call(
          tabs,
          document.getElementById("tab-" + window.location.hash.substring(1))
        );
      }
      if (typeof tab === "undefined" || tab < 0) {
        tab = 0;
        focus = false;
      } else {
        focus = true;
      }
      return {
        index: tab,
        focus: focus
      };
    };

    var switchTab = function(oldIndex, tabInfo) {
      if (typeof oldIndex !== "undefined" && oldIndex > -1) {
        tabs[oldIndex].setAttribute("aria-selected", "false");
        panels[oldIndex].hidden = true;
      }

      tabs[tabInfo.index].setAttribute("aria-selected", "true");
      panels[tabInfo.index].hidden = false;

      if (typeof oldIndex !== "undefined" && tabInfo.focus) {
        panels[tabInfo.index].focus();
      }
    };

    Array.prototype.forEach.call(tabs, function(tab, i) {
      tab.setAttribute("role", "tab");
      tab.setAttribute("aria-selected", "false");
      tab.parentNode.setAttribute("role", "presentation");
      tab.id = "tab-" + tab.getAttribute("href").substring(1);

      var panel = panels[i];
      panel.setAttribute("role", "tabpanel");
      panel.setAttribute("tabindex", "-1");
      panel.setAttribute("aria-labelledby", tab.id);
      panel.hidden = true;
    });

    // Use event delegation to listen to all 'keyup' events
    // within `tablist`. If 'Space' was pressed on a tab
    // element, then click it triggering a 'hashchange'.
    tablist.addEventListener("keyup", function(e) {
      var isTab = e.target.matches('[role="tab"]');
      var keyCode = e.code;

      if (isTab && keyCode === "Space") {
        e.target.click();
      }
    });

    window.addEventListener(
      "hashchange",
      function(e) {
        var selected = tablist.querySelector('[aria-selected="true"]');
        var oldIndex = selected
          ? Array.prototype.indexOf.call(tabs, selected)
          : undefined;
        switchTab(oldIndex, tabInfo());
      },
      false
    );

    window.addEventListener("DOMContentLoaded", function() {
      switchTab(undefined, tabInfo());
    });
  };
})();
}*/
// Codi modificat perquè funcioni el canvi de pestanyes amb tecles de fletxa esquerra / dreta
if ($(".gel-tabs").length > 0) {

  document.addEventListener("DOMContentLoaded", function(event) {
    window.gel && gel.Tabs.init();

    var tabsElem = document.querySelector(".gel-tabs");
    var tabs = new gel.Tabs.constructor(tabsElem);
  });

  (function() {
    if (!window.gel) {
      window.gel = {};
    }
    var self = (gel.Tabs = {});

    self.init = function() {};

    self.constructor = function(elem) {
      var tablist = elem.querySelector(".tabs-list");
      var tabs = elem.querySelectorAll(".tabs-list a");
      var panels = elem.querySelectorAll("section[id]");
      tablist.setAttribute("role", "tablist");

      var tabInfo = function() {
        var focus;
        var tab;
        if (window.location.hash) {
          tab = Array.prototype.indexOf.call(
            tabs,
            document.getElementById("tab-" + window.location.hash.substring(1))
          );
        }
        if (typeof tab === "undefined" || tab < 0) {
          tab = 0;
          focus = false;
        } else {
          focus = true;
        }
        return {
          index: tab,
          focus: focus
        };
      };

      var switchTab = function(oldIndex, tabInfo) {
        if (typeof oldIndex !== "undefined" && oldIndex > -1) {
          tabs[oldIndex].setAttribute("aria-selected", "false");
          panels[oldIndex].hidden = true;
        }

        tabs[tabInfo.index].setAttribute("aria-selected", "true");
        panels[tabInfo.index].hidden = false;

        if (typeof oldIndex !== "undefined" && tabInfo.focus) {
          panels[tabInfo.index].focus();
        }
      };

      Array.prototype.forEach.call(tabs, function(tab, i) {
        tab.setAttribute("role", "tab");
        tab.setAttribute("aria-selected", "false");
        tab.parentNode.setAttribute("role", "presentation");
        tab.id = "tab-" + tab.getAttribute("href").substring(1);

        var panel = panels[i];
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        panel.setAttribute("aria-labelledby", tab.id);
        panel.hidden = true;
      });

      tablist.addEventListener("keyup", function(e) {
        var isTab = e.target.matches('[role="tab"]');
        var keyCode = e.code;

        if (isTab && keyCode === "Space") {
          e.target.click();
        }
      });

      // Adding keyboard arrow navigation
      tablist.addEventListener("keydown", function(e) {
        var isTab = e.target.matches('[role="tab"]');
        var keyCode = e.code;

        if (isTab && (keyCode === "ArrowRight" || keyCode === "ArrowLeft")) {
          e.preventDefault();

          var selected = tablist.querySelector('[aria-selected="true"]');
          var currentIndex = selected ? Array.prototype.indexOf.call(tabs, selected) : 0;

          // Depending on whether the left or right arrow key was pressed,
          // switch to the previous or next tab.
          if (keyCode === "ArrowLeft" && currentIndex > 0) {
            switchTab(currentIndex, { index: currentIndex - 1, focus: false });
          } else if (keyCode === "ArrowRight" && currentIndex < tabs.length - 1) {
            switchTab(currentIndex, { index: currentIndex + 1, focus: false });
          }
        }
      });

       window.addEventListener(
        "hashchange",
        function(e) {
          var selected = tablist.querySelector('[aria-selected="true"]');
          var oldIndex = selected
            ? Array.prototype.indexOf.call(tabs, selected)
            : undefined;
          switchTab(oldIndex, tabInfo());
        },
        false
      );

      window.addEventListener("DOMContentLoaded", function() {
        switchTab(undefined, tabInfo());
      });
    };
  })();
}